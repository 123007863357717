import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import meetingsService from '../services/meeting.services';
import Container from '../components/container/Container';
import Layout from '../components/layout/Layout';
import { format } from 'date-fns';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Meetings.css';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { setTokenCookie } from '../utils/cookies';

const JoinMeetingButton = ({ meetingId }) => {
  const onClick = async () => {
    const {
      data: { meeting, accessToken },
    } = await meetingsService.joinMeeting(meetingId);
    setTokenCookie(accessToken);
    window.open(
      `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
      '_blank'
    );
  };
  return (
    <button
      onClick={onClick}
      className="bg-blue-600 hover:bg-blue-800"
      style={{
        color: 'white',
        padding: '8px 14px',
        textAlign: 'center',
        textDecoration: 'none',
        fontSize: '16px',
        borderRadius: '5px',
        transition: 'background-color 0.3s',
      }}
    >
      Join Meeting
    </button>
  );
};

const deletePopupStyles = {
  width: '350px',
  padding: '20px',
  maxWidth: '90vw',
  maxHeight: '90vh',
  borderRadius: '10px',
  color: '#000',
};
const Meetings = () => {
  const { auth } = useContext(AuthContext);
  const [meetings, setMeetings] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [activeTab, setActiveTab] = useState('creator');

  const navigate = useNavigate();

  async function fetchMeetings() {
    try {
      const data = await meetingsService.getAllMeetings();
      setMeetings(data.meetings);
    } catch (err) {
      setError(err.message);
    }
  }

  useEffect(() => {
    if (auth.user) {
      setCurrentUser(auth.user);
    }

    fetchMeetings();
  }, [auth]);

  const createdMeetings = useMemo(
    () => meetings.filter((meeting) => meeting.createdBy === currentUser.id),
    [meetings, currentUser.id]
  );
  const moderatingMeetings = useMemo(
    () =>
      meetings.filter(
        (meeting) =>
          meeting.moderators && meeting.moderators.includes(currentUser.email)
      ),
    [meetings, currentUser.email]
  );
  const invitedMeetings = useMemo(
    () =>
      meetings.filter(
        (meeting) =>
          meeting.invitees && meeting.invitees.includes(currentUser.email)
      ),
    [meetings, currentUser.email]
  );

  const filteredMeetings = (meetingsList) => {
    return meetingsList.filter(
      (meeting) =>
        meeting.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        meeting.meetingId.includes(searchTerm)
    );
  };

  async function handleDelete(id, meetingId) {
    await meetingsService.deleteMeeting(id).then((result) => {
      const updatedMeetings = meetings.filter((meeting) => meeting.id !== id);
      setMeetings(updatedMeetings);

      toast.success(
        'Meeting with id ' + meetingId + ' has been deleted successfully',
        {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    });
  }

  const renderMeetingCard = (meeting) => (
    <div
      key={meeting.id}
      className="p-4 border rounded shadow-md bg-gray-800 text-white"
    >
      <h3 className="text-xl font-bold">{meeting.title}</h3>
      <p className="text-gray-400">Meeting Id: {meeting.meetingId}</p>
      <p className="text-gray-400">
        Start Time: {format(new Date(meeting.startTime), 'dd MMM yyyy / p')}
      </p>
      <div className="mt-2 flex flex-wrap gap-4">
        {(meeting.createdBy === currentUser.id ||
          (meeting.moderators &&
            meeting.moderators.includes(currentUser.email))) && (
          <>
            <button
              onClick={() => {
                navigate(`/meeting/${meeting.meetingId}/edit`);
              }}
              className="text-blue-500 hover:underline"
            >
              Edit
            </button>
            <Popup
              trigger={
                <button className="text-red-500 hover:underline">Delete</button>
              }
              modal
              contentStyle={deletePopupStyles}
            >
              {(close) => (
                <div className="modal">
                  <div
                    className="header"
                    style={{ fontSize: '1.5em', marginBottom: '10px' }}
                  >
                    Confirm delete
                  </div>
                  <div className="content mb-6">
                    Are you sure you want to delete this meeting?
                  </div>
                  <div className="actions">
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                      onClick={() => {
                        handleDelete(meeting.id, meeting.meetingId);
                        close();
                      }}
                    >
                      Delete
                    </button>
                    <button
                      className="bg-gray-300 text-black px-4 py-2 rounded"
                      onClick={close}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </Popup>
            {/* <button className="text-red-500 hover:underline" >Delete</button> */}
          </>
        )}

        <div className="ml-auto">
          <JoinMeetingButton meetingId={meeting.meetingId} />
        </div>
      </div>
    </div>
  );

  const getMeetingsForTab = () => {
    switch (activeTab) {
      case 'creator':
        return filteredMeetings(createdMeetings);
      case 'moderator':
        return filteredMeetings(moderatingMeetings);
      case 'invitee':
        return filteredMeetings(invitedMeetings);
      default:
        return [];
    }
  };

  return (
    <Layout>
      <Container>
        <ToastContainer
          position="top-center"
          draggable={false}
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Zoom}
          closeButton={false}
        />
        <div className="w-full px-5 pb-5">
          <div className="flex justify-between flex-wrap items-center mb-4">
            <h2 className="text-4xl font-bold">Meetings</h2>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search by title or ID"
                className="p-2 border rounded outline-none text-black search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Link
                to="/meeting/new"
                className="ml-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
              >
                New Meeting
              </Link>
            </div>
          </div>

          {error && <p className="text-red-500">{error}</p>}

          <div className="mb-4 flex gap-4 meeting-tabs">
            <button
              className={`px-4 py-2 rounded ${
                activeTab === 'creator'
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-gray-200 text-black'
              }`}
              onClick={() => setActiveTab('creator')}
            >
              Created Meetings
            </button>
            <button
              className={`px-4 py-2 rounded ${
                activeTab === 'invitee'
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-gray-200 text-black'
              }`}
              onClick={() => setActiveTab('invitee')}
            >
              Invited Meetings
            </button>
            <button
              className={`px-4 py-2 rounded ${
                activeTab === 'moderator'
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-gray-200 text-black'
              }`}
              onClick={() => setActiveTab('moderator')}
            >
              Moderating Meetings
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {getMeetingsForTab().map(renderMeetingCard)}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Meetings;
