import axios from 'axios';
import { removeTokenCookie } from '../utils/cookies';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && error.response?.status === 401) {
      localStorage.removeItem('accessToken');
      removeTokenCookie();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
