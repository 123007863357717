import React, { useEffect, useState } from 'react';
import { generateCalendarFile } from './utils';

export function CalendarFile({ meeting, updateOpenDropdown }) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const generatedUrl = generateCalendarFile(meeting);

    setUrl(generatedUrl);
  }, []);

  return (
    <a
      href={url}
      download={`meeting_${meeting.meetingId}.ics`}
      onClick={() => updateOpenDropdown(null)}
    >
      Calendar File
    </a>
  );
}
