import React, { useState, useEffect } from 'react';
import JitsiComponent from './Jitsi.component';
import meetingsService from '../../services/meeting.services';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setTokenCookie } from '../../utils/cookies';

function Meeting() {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(true);
  const [meeting, setMeeting] = useState({});
  const params = useParams();
  useEffect(async () => {
    if (localStorage.getItem('accessToken')) {
      await meetingsService
        .joinMeeting(params.id)
        .then((res) => {
          if (res.status === 200) {
            setMeeting(res.data.meeting);
            setTokenCookie(res.data.accessToken);
            setTimeout(() => {
              setIsError(false);
            }, 500);
          }
        })
        .catch((error) => {
          if (!error?.response) {
            setIsError(true);
            console.log('LOCATIONS BOY 222');
          } else if (error.response?.status === 404) {
            setIsError(true);
            toast.error('Meeting not found', {
              style: { backgroundColor: '#212332', color: 'white' },
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              navigate('/');
            }, 2200);
          } else if (error.response?.status === 403) {
            setIsError(true);
            toast.error('You are not invited for this meeting', {
              style: { backgroundColor: '#212332', color: 'white' },
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              navigate('/');
            }, 2200);
          } else if (error.response?.status === 400) {
            setIsError(true);
            toast.error('Invalid meeting code', {
              style: { backgroundColor: '#212332', color: 'white' },
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              navigate('/');
            }, 2200);
          }
        });
    } else {
      await meetingsService
        .joinMeeting(params.id)
        .then((res) => {
          if (res.status === 200) {
            setMeeting(res.data.meeting);
            if (res.data.accessToken) {
              setTokenCookie(res.data.accessToken);
            }
            setTimeout(() => {
              setIsError(false);
            }, 500);
          }
        })
        .catch((error) => {
          if (error) {
            setIsError(true);
            toast.error(
              'Meeting code is incorrect or you need to login in order to join the meeting',
              {
                style: { backgroundColor: '#212332', color: 'white' },
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              }
            );
            setTimeout(() => {
              navigate('/login');
            }, 2200);
          }
        });
    }
  }, []);

  if (isError) {
    return (
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />
    );
  }

  return (
    <div>
      <JitsiComponent element={meeting} />
    </div>
  );
}

export default Meeting;
