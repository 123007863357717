import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function JitsiComponentWithNavigation(props) {
  const navigation = useNavigate(); // extract navigation prop here
  const params = useParams();
  let meeting = props.element;

  return (
    <JitsiComponent navigation={navigation} params={params} meeting={meeting} />
  ); //pass to your component.
}

class JitsiComponent extends Component {
  domain = 'jitsi.suhail.global';
  api = {};

  constructor(props) {
    super(props);
    this.state = {
      room: this.props.params.id,
      user: {
        name: 'Guest',
      },
    };
  }

  startMeet = () => {
    let isMuted = !!this.props.meeting.mute_participants_upon_entry;

    const options = {
      roomName: this.state.room,
      configOverwrite: {
        disableDeepLinking: true,
        startWithVideoMuted: isMuted,
        startWithAudioMuted: isMuted,
      },
      parentNode: document.querySelector('#meet'),
      userInfo: {
        displayName: this.state.user.name,
      },
    };

    let accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      options.jwt = accessToken;
    }

    this.api = new window.JitsiMeetExternalAPI(this.domain, options);
    this.api.addEventListeners({
      readyToClose: () => {
        this.props.navigation('/');
        return;
      },
    });
  };

  // custom events
  executeCommand(command) {
    this.api.executeCommand(command);
    if (command === 'hangup') {
      console.log('hangup');
    }

    if (command === 'toggleAudio') {
      this.setState({ isAudioMuted: !this.state.isAudioMuted });
    }

    if (command === 'toggleVideo') {
      this.setState({ isVideoMuted: !this.state.isVideoMuted });
    }
  }

  componentDidMount() {
    if (window.JitsiMeetExternalAPI) {
      this.startMeet();
    } else {
      alert('Suhail Meets not available');
    }
  }

  render() {
    return (
      <>
        <div id="meet" style={{ width: '100%', height: '100vh' }}></div>
      </>
    );
  }
}

// export default JitsiComponent;
