import React from 'react';
import { useNavigate } from 'react-router-dom';
import meetingsService from '../../services/meeting.services';

function Host() {
  async function handleClick() {
    const start_time = new Date();

    const meetupData = {
      title: 'New meeting',
      description: '',
      startTime: start_time,
      password: '',
      hasWaitingRoom: false,
      muteParticipantsUponEntry: false,
      allowGuests: true,
      invitees: null,
      moderators: null,
    };

    const { meeting } = await meetingsService.postMeetings(meetupData);
    window.open(
      `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
      '_blank'
    );
  }

  return (
    <div
      className="my-3 rounded-md text-center items-center p-6"
      style={{ backgroundColor: 'rgba(45, 49, 66, 0.75)' }}
    >
      <h2 className="font-bold text-xl">Host A Meeting</h2>
      <p className="m-3">Start Meeting Now</p>
      <button
        onClick={() => handleClick()}
        className="flex-shrink-0 bg-teal-500 text-sm text-white py-2 px-2 w-11/12 font-bold"
        type="button"
        style={{ backgroundColor: 'rgb(242, 109, 33)' }}
      >
        Host
      </button>
    </div>
  );
}

export default Host;
