import React from 'react';
import side_logo from './login-bg.png';
import './Signup.css';
import './bg.css';
import { useState, useEffect } from 'react';

function SigninForm(props) {
  const [value, setValue] = useState('1');
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    let img = new Image();
    const date = new Date();
    const num = Math.round(Math.random() * 30 + 1);
    setImgSrc(`${process.env.PUBLIC_URL}/background${num}blur.jpg`);
    img.src = `${process.env.PUBLIC_URL}/background${num}.jpg`;
    img.onload = () => {
      setImgSrc(img.src);
    };
  }, []);

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          zIndex: '9999',
          inset: '16px',
          pointerEvents: 'none',
        }}
      ></div>
      <div
        className="background1"
        style={{ backgroundImage: `url("${imgSrc}")` }}
      >
        <div className="loginBackgroundShadow h-screen bg-transparent pt-12 pb-12 overflow-auto">
          <div
            className="flex rounded-lg justify-center max-w-sm mx-auto my-auto w-11/12 lg:w-10/12 overflow-auto sm:w-1/2"
            style={{
              backgroundColor: 'rgb(25, 27, 40)',

              opacity: 0.8375,
              boxShadow: '0px 0px 12.5px 0px black',
            }}
          >
            {/* <div className="w-1/2 md:w-7/12 lg:w-1/2 hidden md:block">
            <img className="w-full rounded-lg" src={side_logo} alt="login-bg" />
          </div> */}
            <div className="m-auto w-full md:w-5/12 lg:w-1/2">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SigninForm;
