import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import './Signin.css';
import logo from './suhail-logo.png';
import AuthService from '../services/auth.services';
import SigninForm from './SigninForm';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const ChangePassowrd = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfrimPassword] = useState('');

  const navigate = useNavigate();
  const params = useParams();

  const handleChangePassowrd = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      const data = {
        password: password,
        confrim_password: confirmPassword,
      };
      await AuthService.changePassword(data, params.id).then((res) => {
        if (!res.error) {
          navigate('/');
        } else if (res.error === 'The passwords entered do not match') {
          toast.error('The passwords entered do not match', {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.error === 'The link has expired, please try again.') {
          toast.error(
            'The link has expired, please try to reset password again.',
            {
              style: { backgroundColor: '#212332', color: 'white' },
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else if (res.error === 'Please fill out all fields') {
          toast.warn('Please fill out all fields', {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } else {
      toast.error('The passwords entered do not match', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <SigninForm>
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />
      <form
        action="#"
        className="flex flex-col content-center py-6 text-center p-1/10"
      >
        <img className="w-1/3 h-1/3 mx-auto" src={logo} alt="logo" />
        <div className="my-1">
          <p className="text-sm">Change Your Password</p>
          <h2 className="text-2xl">Enter Your New Password</h2>
        </div>
        <div className="form-group">
          <input
            name="change password"
            type="password"
            placeholder="Enter password here"
            className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <div>
            <input
              name="change password"
              type="password"
              placeholder="Confirm your new password"
              className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
              onChange={(e) => setConfrimPassword(e.target.value)}
            />
          </div>
        </div>
        <div>
          <button
            onClick={handleChangePassowrd}
            className="text-white rounded-sm py-3 my-3 w-11/12"
            type="submit"
            style={{ backgroundColor: 'rgb(47, 193, 157)' }}
          >
            CHANGE PASSWORD
          </button>
        </div>
      </form>
    </SigninForm>
  );
};

export default ChangePassowrd;
