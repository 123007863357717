import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import authService from '../services/auth.services';

function Logout() {
  const navigate = useNavigate();

  const log_Out = () => {
    authService.logout();
  };

  return (
    <ul>
      <Link to="/login" onClick={log_Out}>
        <li className="pro-menu-item text-sm font-semibold bg-transparent rounded-lg hover:bg-gray-600 focus:bg-gray-600 focus:text-white hover:text-white focus:outline-none focus:shadow-outline">
          <div className="pro-inner-item" tabIndex="0" role="button">
            <span className="pro-icon-wrapper">
              <span className="pro-icon">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
              </span>
            </span>

            <span className="pro-item-content">Sign Out</span>
          </div>
        </li>
      </Link>
    </ul>
  );
}

export default Logout;
