import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams, parsePath } from 'react-router-dom';
import './Signin.css';
import logo from './suhail-logo.png';
import AuthService from '../services/auth.services';
import SigninForm from './SigninForm';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const GuestLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('Guest');
  const [lastName, setLastName] = useState('');
  const [showGuest, setShowGuest] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(async () => {
    if (localStorage.getItem('accessToken')) {
      await AuthService.login(email.toLowerCase(), password).then((res) => {
        if (!res.error) {
          navigate('/');
        } else {
          localStorage.removeItem('accessToken');
          navigate('/login');
        }
      });
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    await AuthService.login(email.toLowerCase(), password).then((res) => {
      if (!res.error) {
        navigate('/');
      } else if (res.error === 'Email does not exist') {
        toast.error('Email does not exist', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (res.error === 'Password is incorrect') {
        toast.error('Password is incorrect', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (res.error === 'Please fill out all fields') {
        toast.warn('Please fill out all fields', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  return (
    <SigninForm>
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />

      <form
        action="#"
        className="flex flex-col content-center py-4 text-center p-1/10"
      >
        <img className="w-1/3 h-1/3 mx-auto" src={logo} alt="logo" />
        <div className="my-1">
          <p className="text-sm">Welcome to meeting: {params.id}</p>
          <h2 className="text-2xl">You are not signed in</h2>
        </div>
        {showLogin ? (
          <div>
            <div className="form-group">
              <input
                name="phoneEmail"
                type="text"
                placeholder="Email"
                className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <div>
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={() => {
                setShowLogin(true);
              }}
              className="text-white rounded-sm py-3 my-3 w-11/12"
              type="submit"
              style={{ backgroundColor: 'rgb(47, 193, 157)' }}
            >
              LOGIN
            </button>
            <button
              onClick={() => {
                setShowLogin(false);
              }}
              className="text-white rounded-sm py-3 my-3 w-11/12"
              type="submit"
              style={{ backgroundColor: '#dc2626' }}
            >
              BACK
            </button>
          </div>
        ) : (
          <div></div>
        )}

        {showGuest ? (
          <div>
            <div className="form-group">
              <input
                name="name"
                type="text"
                placeholder="First Name"
                className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <div>
                <input
                  name="name"
                  type="text"
                  placeholder="Last Name"
                  className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={() => {
                setShowGuest(true);
              }}
              className="text-white rounded-sm py-3 my-3 w-11/12"
              type="submit"
              style={{ backgroundColor: 'rgb(47, 96, 193)' }}
            >
              CONTINUE AS GUEST
            </button>
            <button
              onClick={() => {
                setShowGuest(false);
              }}
              className="text-white rounded-sm py-3 my-3 w-11/12"
              type="submit"
              style={{ backgroundColor: '#dc2626' }}
            >
              BACK
            </button>
          </div>
        ) : (
          <div></div>
        )}
        {/* <div>
          <button
            onClick={() => {setShowGuest(true)}}
            className="text-white rounded-sm py-3 my-3 w-11/12"
            type="submit"
            style={{ backgroundColor: "rgb(47, 96, 193)" }}
          >
            CONTINUE AS GUEST
          </button>
          <button
            onClick={() => {setShowLogin(true)}}
            className="text-white rounded-sm py-3 my-3 w-11/12"
            type="submit"
            style={{ backgroundColor: "rgb(47, 193, 157)" }}
          >
            LOGIN TO YOUR ACCOUNT
          </button> 
        </div> */}
      </form>
      {showLogin || showGuest ? (
        <div></div>
      ) : (
        <div>
          <div className="flex flex-col content-center ml-8 py-6 text-center p-1/10">
            <button
              onClick={() => {
                setShowGuest(true);
              }}
              className="text-white rounded-sm py-3 my-3 w-11/12"
              type="submit"
              style={{ backgroundColor: 'rgb(47, 96, 193)' }}
            >
              CONTINUE AS GUEST
            </button>
            <button
              onClick={() => {
                setShowLogin(true);
              }}
              className="text-white rounded-sm py-3 my-3 w-11/12"
              type="submit"
              style={{ backgroundColor: 'rgb(47, 193, 157)' }}
            >
              LOGIN TO YOUR ACCOUNT
            </button>
            <p className="text-md my-2 mr-6">
              Don't have an account?
              <Link
                to={'/register'}
                className="no-underline text-white hover:no-underline hover:text-white focus:no-underline focus:text-white"
              >
                &nbsp; Register
              </Link>
            </p>
          </div>
        </div>
      )}
    </SigninForm>
  );
};

export default GuestLogin;
