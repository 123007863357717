import React, { useState, useEffect, useMemo } from 'react';
import Layout from '../components/layout/Layout';
import Container from '../components/container/Container';
import Avatar from './Profile/components/Profile';
import userService from '../services/user.services';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './Profile.css';
import { Link, useNavigate } from 'react-router-dom';
import authService from '../services/auth.services';

const Profile = () => {
  const [user, setUser] = useState({});
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await userService.getUserInfo();
        const userData = response.data;
        setUser(userData);
        setProfileData({
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          phoneNumber: userData.phoneNumber || '',
          email: userData.email || '',
        });
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const defaultValues = useMemo(
    () => ({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      phoneNumber: user.phoneNumber || '',
      email: user.email || '',
    }),
    [user]
  );

  const defaultPasswordValues = useMemo(
    () => ({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }),
    []
  );

  const hasChanges = useMemo(
    () => JSON.stringify(defaultValues) !== JSON.stringify(profileData),
    [defaultValues, profileData]
  );
  const hasPasswordChanges = useMemo(
    () =>
      JSON.stringify(defaultPasswordValues) !== JSON.stringify(passwordData),
    [defaultPasswordValues, passwordData]
  );

  const navigate = useNavigate();

  const validateProfile = () => {
    const vErrors = {};

    if (!profileData.firstName) vErrors.firstName = 'First name is required';
    if (!profileData.lastName) vErrors.lastName = 'Last name is required';

    if (!profileData.phoneNumber)
      vErrors.phoneNumber = 'Phone number is required';
    else if (!isValidPhoneNumber(profileData.phoneNumber))
      vErrors.phoneNumber = 'Invalid phone number';

    setErrors(vErrors);
    return Object.keys(vErrors).length === 0;
  };

  const validatePassword = () => {
    const vErrors = {};

    if (!passwordData.oldPassword)
      vErrors.oldPassword = 'Old password is required';
    if (!passwordData.newPassword)
      vErrors.newPassword = 'New password is required';
    if (!passwordData.confirmPassword)
      vErrors.confirmPassword = 'Confirm password is required';
    if (passwordData.newPassword !== passwordData.confirmPassword)
      vErrors.confirmPassword = 'Passwords do not match';

    setErrors(vErrors);
    return Object.keys(vErrors).length === 0;
  };

  const handleProfileChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const displayError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(err, {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      toast.error(error, {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onSubmitProfile = async (e) => {
    e.preventDefault();

    if (!validateProfile()) return;

    setIsSubmitting(true);

    try {
      const { firstName, lastName, phoneNumber } = profileData;

      const res = await userService.updateProfile({
        firstName,
        lastName,
        phoneNumber,
      });
      if (!res.error) {
        toast.success('Profile updated successfully', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });

        setTimeout(() => navigate('/'), 750);
      } else {
        displayError(res.error);
      }
    } catch (error) {
      displayError(error.message);
    }

    setIsSubmitting(false);
  };

  const onSubmitPassword = async (e) => {
    e.preventDefault();

    if (!validatePassword()) return;

    setIsSubmitting(true);

    try {
      const payload = {
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
      };

      const res = await userService.updatePassword(payload);
      if (!res.error) {
        toast.success('Password updated successfully', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });

        setTimeout(() => navigate('/'), 750);
      } else {
        displayError(res.error);
      }
    } catch (error) {
      displayError(error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <Layout>
      <Container>
        <ToastContainer
          position="top-center"
          draggable={false}
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Zoom}
          closeButton={false}
        />
        <div className="profile-page">
          <div className="w-full mb-8">
            <form onSubmit={onSubmitProfile}>
              <div
                className="rounded p-6"
                style={{ backgroundColor: 'rgba(25, 27, 40, 0.75)' }}
              >
                <div className="flex justify-between">
                  <h3 className="hidden md:block section-title">
                    Account Information
                  </h3>
                  <button
                    type="submit"
                    className="hidden md:block submit-button"
                    disabled={!hasChanges || isSubmitting}
                  >
                    Update Profile
                  </button>
                </div>
                <div className="flex justify-between">
                  <Avatar user={user} />
                </div>
                <div className="flex flex-col">
                  <div className="form-group">
                    <label className="font-bold">First Name:</label>
                    <input
                      type="text"
                      name="firstName"
                      value={profileData.firstName}
                      onChange={handleProfileChange}
                    />
                    {errors.firstName && (
                      <div className="error-message">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="font-bold">Last Name:</label>
                    <input
                      type="text"
                      name="lastName"
                      value={profileData.lastName}
                      onChange={handleProfileChange}
                    />
                    {errors.lastName && (
                      <div className="error-message">{errors.lastName}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="font-bold">Phone Number:</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={profileData.phoneNumber}
                      onChange={handleProfileChange}
                    />
                    {errors.phoneNumber && (
                      <div className="error-message">{errors.phoneNumber}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="font-bold">Login Email:</label>
                    <input
                      disabled
                      type="text"
                      name="email"
                      value={profileData.email}
                      onChange={handleProfileChange}
                    />
                  </div>
                  <button
                    type="submit"
                    id="updatePasswordBtn"
                    className="mt-3 block md:hidden submit-button"
                    disabled={!hasPasswordChanges || isSubmitting}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="w-full">
            <form onSubmit={onSubmitPassword}>
              <div
                className="rounded p-6"
                style={{ backgroundColor: 'rgba(25, 27, 40, 0.75)' }}
              >
                <div className="flex justify-between mb-6">
                  <h3 className="section-title">Password and Security</h3>
                  <button
                    type="submit"
                    id="updatePasswordBtn"
                    className="hidden md:block submit-button"
                    disabled={!hasPasswordChanges || isSubmitting}
                  >
                    Update Password
                  </button>
                </div>
                <div className="flex flex-col">
                  <div className="form-group">
                    <label className="font-bold">Old Password:</label>
                    <input
                      type="password"
                      name="oldPassword"
                      value={passwordData.oldPassword}
                      onChange={handlePasswordChange}
                    />
                    {errors.oldPassword && (
                      <div className="error-message">{errors.oldPassword}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="font-bold">New Password:</label>
                    <input
                      type="password"
                      name="newPassword"
                      value={passwordData.newPassword}
                      onChange={handlePasswordChange}
                    />
                    {errors.newPassword && (
                      <div className="error-message">{errors.newPassword}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="font-bold">Confirm Password:</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={passwordData.confirmPassword}
                      onChange={handlePasswordChange}
                    />
                    {errors.confirmPassword && (
                      <div className="error-message">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    id="updatePasswordBtn"
                    className="mt-3 block md:hidden submit-button"
                    disabled={!hasPasswordChanges || isSubmitting}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="w-full my-2 block md:hidden">
            <div
              className="rounded p-6"
              style={{ backgroundColor: 'rgba(25, 27, 40, 0.75)' }}
            >
              <Link
                to="/login"
                onClick={() => authService.logout()}
                className="submit-button block text-center"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Profile;
