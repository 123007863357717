import axiosInstance from '../api/axios';
import authHeader from './auth-header';
import { setTokenCookie } from '../utils/cookies';

const API_URL = '/api/user';

const getUserInfo = async () => {
  return axiosInstance.get(`${API_URL}/auth/me`, { headers: authHeader() });
};

const updateProfile = async (data) => {
  return axiosInstance
    .patch(API_URL, data, { headers: authHeader() })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
      }

      return response.data;
    })
    .catch((error) => {
      throw error.response
        ? error.response.data
        : new Error('Failed to update profile');
    });
};

const updatePassword = async (data) => {
  return axiosInstance
    .put(`${API_URL}/auth/update-password`, data, { headers: authHeader() })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
      }

      return response.data;
    })
    .catch((error) => {
      throw error.response
        ? error.response.data
        : new Error('Failed to update password');
    });
};

const userService = {
  getUserInfo,
  updateProfile,
  updatePassword,
};

export default userService;
