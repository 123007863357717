import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './components/home/Sidebar.js';
import Layout from './components/layout/Layout.js';
import Meeting from './components/meetups/Meeting.js';
import Protected from './Protected.js';
import Redirect from './components/Redirect.js';
import Login from './pages/Login.js';
import Signin from './pages/Signin';
import Shedule from './pages/Shedule.js';
import Meetings from './pages/Meetings.js';
import Signup from './pages/Signup';
import Home from './pages/Home';
import Profile from './pages/Profile';
import ChangePassword from './pages/ChangePassword';
import Join from './components/meetups/Join.js';
import ReactGA from 'react-ga';
import GuestLogin from './pages/GuestLogin.js';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Routes>
      <Route path="/" element={<Protected component={Home} />}>
        {' '}
      </Route>
      <Route path="/login" element={<Signin></Signin>}>
        {' '}
      </Route>
      <Route path="/register" element={<Signup></Signup>}>
        {' '}
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/change-password/:id" element={<ChangePassword />}></Route>
      <Route path="/guest-login/:id" element={<GuestLogin></GuestLogin>}>
        {' '}
      </Route>
      <Route
        path="/profile"
        element={<Protected component={Profile} />}
      ></Route>
      <Route path="/meetings" element={<Protected component={Meetings} />} />
      <Route
        path="/meeting/new"
        element={<Protected component={Shedule} />}
      ></Route>
      <Route
        path="/meeting/:meetingId/edit"
        element={<Protected component={Shedule} />}
      />
      <Route exact path="/:id" element={<Meeting />}>
        {' '}
      </Route>
      <Route path="*" element={<Redirect />}>
        {' '}
      </Route>
    </Routes>
  );
}

export default App;
