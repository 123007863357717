import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import meetingsService from '../../services/meeting.services';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setTokenCookie } from '../../utils/cookies';

function Join() {
  const [code, setCode] = useState('');
  const [isError, setIsError] = useState(true);

  let navigate = useNavigate();

  function handleJoin() {
    let path = code;

    meetingsService
      .joinMeeting(code)
      .then((result) => {
        if (result.status === 200) {
          setIsError(false);
          const { accessToken, meeting } = result.data;
          setTokenCookie(accessToken);
          window.open(
            `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
            '_blank'
          );
        }
      })
      .catch((error) => {
        if (!error?.response) {
          setIsError(true);
        } else if (error.response?.status === 404) {
          setIsError(true);
          toast.error('Meeting not found', {
            style: {
              backgroundColor: '#212332',
              color: 'white',
            },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (error.response?.status === 403) {
          setIsError(true);
          toast.error('You are not invited for this meeting', {
            style: {
              backgroundColor: '#212332',
              color: 'white',
            },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (error.response?.status === 400) {
          setIsError(true);
          toast.error('Invalid meeting code', {
            style: {
              backgroundColor: '#212332',
              color: 'white',
            },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />
      <div
        className="my-3 rounded-md text-center p-6"
        style={{ backgroundColor: 'rgba(45, 49, 66, 0.75)' }}
      >
        <h2 className="font-bold text-xl">Join A Meeting</h2>
        <p className="m-3">Instantly join a meeting</p>
        <div className="flex bg-white">
          <input
            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-2 px-2 leading-tight focus:outline-none"
            type="text"
            placeholder="Enter code"
            onChange={(e) => setCode(e.target.value)}
          />
          <button
            className="flex-shrink-0 bg-teal-500 text-sm text-white py-2 px-2 font-bold"
            type="button"
            style={{ backgroundColor: 'rgb(41, 196, 155)' }}
            onClick={handleJoin}
          >
            JOIN
          </button>
        </div>
      </div>
    </>
  );
}

export default Join;
