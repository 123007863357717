import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Upcoming from '../components/meetups/Upcoming';
import Host from '../components/meetups/Host';
import Join from '../components/meetups/Join';
import Container from '../components/container/Container';
import Layout from '../components/layout/Layout';
import meetingsService from '../services/meeting.services';
import { format } from 'date-fns';
import { setTokenCookie } from '../utils/cookies';

const JoinMeetingButton = ({ meetingId }) => {
  const onClick = async () => {
    const {
      data: { meeting, accessToken },
    } = await meetingsService.joinMeeting(meetingId);
    setTokenCookie(accessToken);
    window.open(
      `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
      '_blank'
    );
  };
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: '#4CAF50', // Green background
        border: 'none', // No borders
        color: 'white', // White text
        padding: '10px 20px', // Padding
        textAlign: 'center', // Centered text
        textDecoration: 'none', // No underline
        display: 'inline-block', // Inline-block display
        fontSize: '16px', // Font size
        margin: '4px 2px', // Margin
        cursor: 'pointer', // Pointer cursor on hover
        borderRadius: '5px', // Rounded corners
        transition: 'background-color 0.3s', // Smooth background color transition
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = '#45a049'; // Darker green on hover
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = '#4CAF50'; // Original green
      }}
    >
      Join Meeting
    </button>
  );
};

function Home() {
  const [upcomingTitle, setUpcomingTitle] = useState('');
  const [id, setId] = useState('');
  const [isUpcoming, setIsUpcoming] = useState(false);

  const updateFirstMeetup = (updates) => {
    // update upcoming meeting from child component

    if (updates.id !== undefined) setId(updates.id);
    if (updates.upcomingTitle !== undefined)
      setUpcomingTitle(updates.upcomingTitle);
    if (updates.isUpcoming !== undefined) setIsUpcoming(updates.isUpcoming);
  };

  return (
    <Layout>
      <Container>
        <div className="p-2 flex flex-row flex-container my-3">
          <div className="groupHome w-full md:w-1/2 px-0 lg:px-2">
            <Join />
          </div>
          <div className="w-full px-0 lg:px-2">
            <div
              className="my-3 rounded-md text-center items-center p-3.5"
              style={{ backgroundColor: 'rgba(45, 49, 66, 0.75)' }}
            >
              <div
                className="rounded px-1 md:px-2 py-2 md:py-3"
                // style={{ backgroundColor: "rgba(34, 37, 53, 0.75)" }}
              >
                <h2 className="font-bold text-xl">Upcoming Meeting</h2>
                {isUpcoming ? (
                  <div>
                    <p className="m-3">{upcomingTitle}</p>
                    <JoinMeetingButton meetingId={id} />
                  </div>
                ) : (
                  <div>
                    <p className="m-3">
                      <b>There is no upcoming meeting </b>
                    </p>
                    <p className="m-3 text-blue-700 cursor-pointer">
                      <b>View All Meetings </b>
                    </p>
                  </div>
                )}
                {/* <p className="m-3">Title  &amp; Time</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 flex flex-row flex-container my-3">
          <div className="w-full md:w-full sm:w-full px-0 lg:px-2">
            <Host />
          </div>
          <div className="w-full lg:w-full px-0 lg:px-2">
            <div
              className="my-3 rounded-md text-center items-center p-6"
              style={{ backgroundColor: 'rgba(45, 49, 66, 0.75)' }}
            >
              <h2 className="font-bold text-xl">Schedule a Meeting</h2>
              <p className="m-3">Flexible Date &amp; Time</p>
              <Link to="/meeting/new">
                <button
                  className="flex-shrink-0 bg-teal-500 text-sm text-white py-2 px-2 w-11/12 font-bold"
                  type="button"
                  style={{ backgroundColor: 'rgb(134, 138, 255)' }}
                >
                  SCHEDULE
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div
          className="md:w-full mb-3 rounded-md p-6 w-full lg:w-2/3"
          style={{ backgroundColor: 'rgba(45, 49, 66, 0.75)' }}
        >
          <div className="">
            <div
              className="flex justify-between"
              style={{ alignItems: 'center' }}
            >
              <h2 className="font-bold text-xl">Upcoming Meetings</h2>
              <Link to="/meetings" className="text-white">
                Show all
              </Link>
            </div>
            <Upcoming updateFirstMeetup={updateFirstMeetup} />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Home;
