// Format date and time
const formatDT = (dateTime) => {
  const date = new Date(dateTime);
  return date
    .toISOString()
    .replace(/[-:]/g, '')
    .replace(/\.\d{3}/, '');
};
function formatDateTime(dateTime) {
  return new Date(dateTime).toISOString();
}

function isMobileDevice() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

// Function to add default duration (1 hour) if endTime is not provided
export function getEndTime(startTime) {
  const startDate = new Date(startTime);
  const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Adds 1 hour
  return endDate.toISOString();
}

// Google Calendar Link
export function generateGoogleCalendarLink(meeting) {
  const { title, startTime } = meeting;
  const endTime = getEndTime(startTime);
  const location = `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`;
  const description = meeting.description
    ? `&details=${encodeURIComponent(meeting.description)}`
    : '';
  const invitees = meeting.invitees?.length
    ? `&add=${meeting.invitees
        .map((email) => encodeURIComponent(email))
        .join(',')}`
    : '';

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    title
  )}&dates=${startTime.replace(/[-:]/g, '').slice(0, -5)}/${endTime
    .replace(/[-:]/g, '')
    .slice(0, -5)}&location=${encodeURIComponent(
    location
  )}${description}${invitees}&trp=false&sprop=&sprop=name:`;
}

// Outlook Calendar Link
export function generateMicrosoftCalendarLink(meeting, emailType) {
  const domain = emailType == 'business' ? 'office' : 'live';

  const { title, startTime } = meeting;
  const endTime = getEndTime(startTime);
  const location = `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`;
  const description = meeting.description
    ? `&body=${encodeURIComponent(meeting.description)}`
    : '';
  const invitees = meeting.invitees?.length
    ? `&to=${meeting.invitees
        .map((email) => encodeURIComponent(email))
        .join(';')}`
    : '';

  const isMobile = isMobileDevice();
  const baseUrl = isMobile
    ? `https://outlook.${domain}.com/calendar/0/deeplink/compose`
    : `https://outlook.${domain}.com/calendar/0/action/compose`;

  return `${baseUrl}?rru=addevent&subject=${encodeURIComponent(
    title
  )}&startdt=${formatDateTime(startTime)}&enddt=${formatDateTime(
    endTime
  )}&location=${encodeURIComponent(location)}${description}${invitees}`;
}

export const generateCalendarFile = (meeting) => {
  // Calendar Object
  let calendarInfo = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    `PRODID:-//${process.env.REACT_APP_MAIN_DOMAIN}//Meeting Invitation`,
    'BEGIN:VEVENT',
    `UID:${Math.random().toString(36).substring(2, 15)}`,
    `DTSTAMP:${formatDT(new Date())}`,
    `DTSTART:${formatDT(meeting.startTime)}`,
    `DTEND:${formatDT(getEndTime(meeting.startTime))}`,
    `SUMMARY:${meeting.title}`,
    `LOCATION:${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
  ];

  if (meeting.description) {
    const description = meeting.description
      .replace(/,/gm, '\\,')
      .replace(/;/gm, '\\;')
      .replace(/\r\n/gm, '\n')
      .replace(/\n/gm, '\\n');

    calendarInfo.push(`DESCRIPTION:${description}`);
  }

  if (meeting.invitees?.length > 0) {
    meeting.invitees.forEach((invitee) => {
      calendarInfo.push(`ATTENDEE;CN=${invitee}:MAILTO:${invitee}`);
    });
  }

  calendarInfo.push('END:VEVENT', 'END:VCALENDAR');
  const calendarString = calendarInfo.join('\r\n');

  const blob = new Blob([calendarString], {
    type: 'text/calendar;charset=utf-8',
  });

  return URL.createObjectURL(blob);
};
