import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired, removeTokenCookie } from './utils/cookies';

function Protected(props) {
  let Component = props.component;
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && !isTokenExpired(accessToken)) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem('accessToken');
      removeTokenCookie();
      navigate('/login');
    }
  }, [navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <Component />
    </div>
  );
}

export default Protected;
