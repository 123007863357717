import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import mediaService from '../../../services/media.service';

const Avatar = ({ user }) => {
  const [avatarUrl, setAvatarUrl] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Fetch the latest avatar for the user
    const fetchAvatar = async () => {
      try {
        const response = await mediaService.getAvatar();
        setAvatarUrl(response.data.avatarUrl);
      } catch (error) {
        console.error('Error fetching avatar:', error);
      }
    };
    fetchAvatar();
  }, []);

  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    try {
      await mediaService.uploadAvatar(selectedFile);

      // Refresh avatar after upload
      const response = await mediaService.getAvatar();
      setAvatarUrl(response.data.avatarUrl);
    } catch (error) {
      console.error('Error uploading avatar:', error);
      alert('Error uploading avatar. Please try again.');
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      <div
        className="w-24 h-24 md:w-36 md:h-36 rounded-full overflow-hidden mb-2 cursor-pointer"
        onClick={handleAvatarClick}
      >
        <img
          src={
            avatarUrl ||
            'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI='
          }
          alt="Avatar"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default Avatar;
