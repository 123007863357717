export function setTokenCookie(token) {
  const domain = process.env.REACT_APP_MAIN_DOMAIN;
  if (domain) {
    document.cookie = `userToken=${token}; domain=.${domain}; path=/; secure; samesite=strict`;
  } else {
    console.error('For cookies to work, set REACT_APP_MAIN_DOMAIN var');
  }
}

export function removeTokenCookie() {
  const domain = process.env.REACT_APP_MAIN_DOMAIN;
  if (domain) {
    document.cookie = `userToken=''; domain=.${domain}; path=/; secure; samesite=strict`;
  }
}

export function isTokenExpired(token) {
  if (!token) return true;

  const decoded = jwtDecode(token);

  if (!decoded || !decoded.exp) return true;

  const currentTime = Math.floor(Date.now() / 1000);

  return decoded.exp < currentTime;
}

function jwtDecode(token) {
  try {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid token structure');
    }

    const base64Url = parts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.log('Invalid jwt token');
    return null;
  }
}
